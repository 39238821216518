import React from "react";
import { Container, Box, Text, Image, Link } from "theme-ui";
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import Marquee from "react-fast-marquee";
import logo from "assets/imageBanner/wsl-logo.svg";
import twitter from "assets/footerImages/twitter.svg";
import instagram from "assets/footerImages/instagram.svg";
import discord from "assets/footerImages/discord.svg";
import telegram from "assets/footerImages/telegram.svg";
import youtube from "assets/footerImages/youtube.svg";
import medium from "assets/footerImages/contact.svg";
import facebook from "assets/footerImages/facebook.svg";
import data from "../../cms/content/footerImages.json";
import card1 from "assets/footerImages/1.png";
import card2 from "assets/footerImages/2.png";
import card3 from "assets/footerImages/3.png";
import card4 from "assets/footerImages/4.png";
import card5 from "assets/footerImages/5.png";
import card6 from "assets/footerImages/6.png";
import card7 from "assets/footerImages/7.png";
import card8 from "assets/footerImages/8.png";
import card9 from "assets/footerImages/9.png";
import card10 from "assets/footerImages/10.png";
import card11 from "assets/footerImages/11.png";
import card12 from "assets/footerImages/12.png";
import card13 from "assets/footerImages/13.png";
import card14 from "assets/footerImages/14.png";
import card15 from "assets/footerImages/15.png";
import card16 from "assets/footerImages/16.png";

const social = { twitter, instagram, discord, telegram, youtube, medium, facebook };

/* const Line = ({ color }) => (
    <hr
        style={{
            color: color,
            height: 1,
        }}
    />
); */

export default function FooterImages({ showCards }) {
    return (
        <Box as="section" id="footerImages" style={{ backgroundColor: "#222" }}>
            {showCards &&
                <>
                    <Box as="div" style={{ display: "flex", justifyContent: "center" }}>
                        <Image src={logo} sx={styles.logo} />
                    </Box>
                    <Box as="div" sx={styles.cardsContainer}>
                        <Marquee gradient={false}>
                            {/*
                            <Image src={cardsFull} alt="cards image" sx={styles.cardsFullImage} /> 
                            <Image src={cardsMob} alt="cards image" sx={styles.cardsMobImage} />
                        */}
                            <Image src={card1} alt="cards image" sx={styles.card} />
                            <Image src={card2} alt="cards image" sx={styles.card} />
                            <Image src={card3} alt="cards image" sx={styles.card} />
                            <Image src={card4} alt="cards image" sx={styles.card} />
                            <Image src={card5} alt="cards image" sx={styles.card} />
                            <Image src={card6} alt="cards image" sx={styles.card} />
                            <Image src={card7} alt="cards image" sx={styles.card} />
                            <Image src={card8} alt="cards image" sx={styles.card} />
                            <Image src={card9} alt="cards image" sx={styles.card} />
                            <Image src={card10} alt="cards image" sx={styles.card} />
                            <Image src={card11} alt="cards image" sx={styles.card} />
                            <Image src={card12} alt="cards image" sx={styles.card} />
                            <Image src={card13} alt="cards image" sx={styles.card} />
                            <Image src={card14} alt="cards image" sx={styles.card} />
                            <Image src={card15} alt="cards image" sx={styles.card} />
                            <Image src={card16} alt="cards image" sx={styles.card} />
                        </Marquee>
                        <Box sx={{ marginBottom: "20px" }}></Box>
                        <Marquee gradient={false} direction="right">
                            {/*
                            <Image src={cardsFull} alt="cards image" sx={styles.cardsFullImage} /> 
                            <Image src={cardsMob} alt="cards image" sx={styles.cardsMobImage} />
                        */}
                            <Image src={card1} alt="cards image" sx={styles.card} />
                            <Image src={card2} alt="cards image" sx={styles.card} />
                            <Image src={card3} alt="cards image" sx={styles.card} />
                            <Image src={card4} alt="cards image" sx={styles.card} />
                            <Image src={card5} alt="cards image" sx={styles.card} />
                            <Image src={card6} alt="cards image" sx={styles.card} />
                            <Image src={card7} alt="cards image" sx={styles.card} />
                            <Image src={card8} alt="cards image" sx={styles.card} />
                            <Image src={card9} alt="cards image" sx={styles.card} />
                            <Image src={card10} alt="cards image" sx={styles.card} />
                            <Image src={card11} alt="cards image" sx={styles.card} />
                            <Image src={card12} alt="cards image" sx={styles.card} />
                            <Image src={card13} alt="cards image" sx={styles.card} />
                            <Image src={card14} alt="cards image" sx={styles.card} />
                            <Image src={card15} alt="cards image" sx={styles.card} />
                            <Image src={card16} alt="cards image" sx={styles.card} />
                        </Marquee>
                    </Box>
                </>
            }
            <Box as="div" sx={styles.footer}>
                <Container sx={styles.container}>
                    <Box sx={styles.logoRow}>
                        <Image src={logo} sx={styles.infoLogo} />
                        <Box as="div" sx={styles.contactsList}>
                            <Box as="div" sx={styles.contactsContainer}>
                                <Text as="span" sx={styles.contactsText}>
                                    <FormattedHTMLMessage id="followUs" /></Text>
                            </Box>
                            <Box as="div" sx={styles.iconsContainer}>
                                {data.map((item, index) => (
                                    <Link sx={{ display: "flex" }} target="_blank" href={item.link} key={index}>
                                        <Image src={social[item.name]} alt={`${item.name} image`} sx={styles.icon} />
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    {/*
                    <Line color="#333" />
                    <Box as="div" sx={styles.infoLanguage}>
                        <Box as="div" sx={styles.logoAndLanguageContainer}>
                            <Image src={logo} sx={styles.infoLogo} />
                            
                            <Box as="div" sx={styles.infoGroup}>
                                <Link href="/" sx={styles.info}>
                                    <FormattedMessage id="rulesPage" /></Link>
                                <Link href="/" sx={styles.info}>
                                    <FormattedMessage id="termsPage" /></Link>
                                <Link href="/" sx={styles.info}>
                                    <FormattedMessage id="privacyPage" /></Link>
                            </Box>
                            
                        </Box>
                        <Box as="div" sx={styles.language}>
                            <Link className={intl.locale == "it" ? 'selected' : 'not-selected'} href="/it">Italiano</Link>
                            <Link className={intl.locale == "en" ? 'selected' : 'not-selected'} href="/">English</Link>
                        </Box>
                    </Box>
                    */}
                </Container>
            </Box>
        </Box>
    );
}

const styles = {
    textLanguage: {
        color: "#fff",
    },
    flag: {
        width: "auto",
        height: ["10px", "6px", "10px"],
        mr: ["10px", "4px", "6px"],
    },
    cardsFullImage: {
        width: "100%",
        "@media screen and (max-width: 1023px)": {
            display: "none",
        },
    },
    logoRow: {
        display: "flex",
        flexDirection: ['column', 'row'],
        justifyContent: ["center", "space-between"],
        alignItems: ["center", null],
        py: [40]
    },
    cardsMobImage: {
        display: "none",
        "@media screen and (max-width: 1023px)": {
            display: "block",
            width: "100%",
        },
    },
    logoAndLanguageContainer: {
        display: "flex",
        justifyContent: "start",
    },
    container: {
        px: ["27px", "60px", "98px"],
    },
    select: {
        p: ["8px", "4px", "8px"],
        border: "none",
        backgroundColor: "#111",
        color: "#fff",
        width: ["150px", "50px", "90px"],
        "@media screen and (min-width: 500px) and (max-width: 1023px)": {
            width: "350px"
        },
    },
    language: {
        mt: ["43px", "0px", "0px"],
        px: ["12px", "5px", "10px"],
        border: ["1px solid #D9D9D9", "2px solid #D9D9D9", "2px solid #D9D9D9"],
        height: "40px",
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: ["10px", "8px", "14px"],
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        gap: "10px",
        "& > *": {
            mx: 10
        }
    },
    info: {
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: ["12px", "12px", "14px"],
        lineHeight: ["26px", "20px", "25px"],
        letterSpacing: "1px",
        display: "block",
    },
    infoGroup: {
        ml: [null, "52px", "119px"],
        width: "197px",
    },
    infoLanguage: {
        mt: ["27px", "42px", "64px"],
        display: "flex",
        justifyContent: ["start", "space-between", "space-between"],
        "@media screen and (max-width: 1023px)": {
            flexDirection: "row",
            flexWrap: "wrap",
        }
    },
    infoLogo: {
        width: ["80px", "62px", "102px"],
        height: "auto",

    },
    icon: {
        mx: ["13px", "14px", "34px"],
        width: ["21px", "24px", "40px"],
        height: "auto",
    },
    iconsContainer: {
        display: "flex",
        justifyContent: "center",
    },
    contactsText: {
        mr: [null, "58px", "58px"],
        color: "#fff",
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: ["12px", "12px", "16px"],
        lineHeight: "32px",
        letterSpacing: "1px",
        textTransform: "uppercase"
    },
    contactsContainer: {
        "@media screen and (max-width: 1023px)": {
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: "29px",
        }
    },
    contactsList: {
        // mb: ["29px", "40px", "52px"],
        // pt: ["25px", "46px", "72px"],
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center"
    },
    footer: {
        backgroundColor: "#111",
    },
    cardsContainer: {
        mb: ["31px", "33px", "58px"],
        pt: ["15px", "30px", "43px"],
    },
    logo: {
        height: ["60px", "60px", "114px"],
        zIndex: 100
    },
    card: {
        width: 240,
        marginRight: 14
    }
};
